import React from "react"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <React.Fragment>
    <SEO title="404: Not found" />
    <div className="hero-container hero-container--support">
        <div className="container">
            <div className="hero-container__wrapper">
                <div className="col wow fadeIn text-center">
                    <h2 className="lead hero-container__subtitle">404: Not Found</h2>
                    <p className="hero-container__desc">Beklager, siden du leter etter finnes ikke.</p>
                </div>
            </div>
        </div>
    </div>
    <main className="main" id="main">
        <section className="section section--regular section--contact">
            <div className="container text-center">
                <h2>Finner du ikke det du leter etter? <br /> Vennligst kontakt en av våre rådgivere.</h2>
                <a href="mailto:post@allegro.no" className="button button--primary">Kontakt oss</a><br/>
                <a href="/">Eller gå forsiden &raquo;</a>
            </div>
        </section>
    </main>
  </React.Fragment>
)

export default NotFoundPage
